import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { Link } from 'gatsby'

import dmon from '../assets/images/artists/dmon/profile/dmon-artist-profile-medium.jpg'
import dmon001 from '../assets/images/artists/dmon/work/dmon-001.jpg'
import dmon002 from '../assets/images/artists/dmon/work/dmon-002.jpg'
import dmon003 from '../assets/images/artists/dmon/work/dmon-003.jpg'
import dmon004 from '../assets/images/artists/dmon/work/dmon-004.jpg'
import dmon005 from '../assets/images/artists/dmon/work/dmon-005.jpg'
import dmon006 from '../assets/images/artists/dmon/work/dmon-006.jpg'
import dmon007 from '../assets/images/artists/dmon/work/dmon-007.jpg'
import dmon008 from '../assets/images/artists/dmon/work/dmon-008.jpg'
import dmon009 from '../assets/images/artists/dmon/work/dmon-009.jpg'
import dmon010 from '../assets/images/artists/dmon/work/dmon-010.jpg'
import dmon011 from '../assets/images/artists/dmon/work/dmon-011.jpg'
import dmon012 from '../assets/images/artists/dmon/work/dmon-012.jpg'
import dmon013 from '../assets/images/artists/dmon/work/dmon-013.jpg'
import dmon014 from '../assets/images/artists/dmon/work/dmon-014.jpg'
import dmon015 from '../assets/images/artists/dmon/work/dmon-015.jpg'
import dmon016 from '../assets/images/artists/dmon/work/dmon-016.jpg'
import dmon017 from '../assets/images/artists/dmon/work/dmon-017.jpg'
import dmon018 from '../assets/images/artists/dmon/work/dmon-018.jpg'
import dmon019 from '../assets/images/artists/dmon/work/dmon-019.jpg'
import dmon020 from '../assets/images/artists/dmon/work/dmon-020.jpg'
import dmon021 from '../assets/images/artists/dmon/work/dmon-021.jpg'
import dmon022 from '../assets/images/artists/dmon/work/dmon-022.jpg'

import emm from '../assets/images/artists/emm/profile/emm-artist-profile.jpg'

import jeanine from '../assets/images/artists/jeanine/profile/jeanine-artist-profile.jpeg'

import christos from '../assets/images/artists/christos/profile/christos-artist-profile-medium.jpg'
import tony from '../assets/images/artists/tony/profile/tony-artist-profile.jpg'

const Artist_Dmon = props => (
  <Layout>
    <SEO
      title="Artist Dmon The Architect"
      keywords={[
        `tattoos in los angeles`,
        `Dmon the architect`,
        `los angeles tattoo artists`,
        `obsidian la tattoo`,
        `tattoos los angeles`,
        `la tattoo studios`,
      ]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>D'mon</h1>
        </header>
        <div className="image">
          <img src={dmon} alt="dmon the architect" className="image left" />
          <p>
            D'mon has worked as a tattoo artist for 21 years, inking people from
            various cultures and all walks of life including celebrities of
            music, film and television. Born and raised in New Zealand, he has
            owned studios in New Zealand and Australia and most recently opened
            Obsidian LA. D'mon apprenticed with late Phil Mathias in his native
            land, and has long-drawn inspiration from authentic expression and
            unconventional thought.{' '}
          </p>
          <p>
            As a creator of forms, The Architect, works beyond the realm of
            appearances, interpreting his client's personal myths to evoke a
            collaborative artistic expression. Intuitively aware, he is able to
            interpret the subtle nuances of his clients' thoughts, dreams, and
            passions grounding his artistic vision in the universal connection
            of mind, body, and spirit.
          </p>
          <hr />
          <h4 style={{ float: `left` }}>
            <a
              href="https://www.instagram.com/dmontattoo/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @dmontattoo
            </a>
          </h4>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>

        <section
          className="tiles"
          style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
        >
          <article
            style={{
              backgroundImage: `url(${dmon001})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon002})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon003})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon004})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon005})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon006})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon007})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon008})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon009})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon010})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon011})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon012})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon013})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon014})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon015})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon016})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon017})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon018})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon019})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon020})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon021})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
          <article
            style={{
              backgroundImage: `url(${dmon022})`,
              backgroundSize: `cover`,
              margin: `1em`,
              width: `25%`,
            }}
          ></article>
        </section>
        <header className="major">
          <h1>More Artists</h1>
        </header>
        <section id="artists" className="tiles">
          {/* <article style={{ backgroundImage: `url(${dmon})` }}>
                            <header className="major">
                                <h3>D'mon - The Architect</h3>
                                <p>See work</p>
                            </header>
                            <Link to="/artist-dmon" className="link primary"></Link>
                        </article> */}
          <article style={{ backgroundImage: `url(${emm})` }}>
            <header className="major">
              <h3>Emily Kay</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-emm" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${christos})` }}>
            <header className="major">
              <h3>Christos</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-christos" className="link primary"></Link>
          </article>

          <article style={{ backgroundImage: `url(${jeanine})` }}>
            <header className="major">
              <h3>Jeanine</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-jeanine" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${tony})` }}>
            <header className="major">
              <h3>Tony</h3>
              <p>See work</p>
            </header>
            <Link to="/artist-tony" className="link primary"></Link>
          </article>
        </section>
      </div>
    </div>
  </Layout>
)

// export const query = graphql`
// query ArtistDmon{
//     allInstaNode (limit: 25) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `
export default Artist_Dmon
